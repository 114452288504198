<script setup lang="ts">
import { useSlots } from 'vue'
import { MenuItemLayout } from '~/src/enums/MenuItem'

interface Props {
  layout: MenuItemLayout
  active?: boolean
}

const slots = useSlots()

const props = defineProps<Props>()

const hasIcon = slots.icon
const hasNotifications = slots.notifications
</script>

<template>
  <button class="menu-item" :class="[`menu-item--${props.layout}`, props.active ? 'menu-item--active' : '']">
    <span class="menu-item__icon" :class="{'hidden': !hasIcon}">
      <slot name="icon" />
    </span>
    <slot />
    <span class="menu-item__notifications" :class="{'hidden': !hasNotifications}">
      <slot name="notifications" />
    </span>
  </button>
</template>
<style scoped lang="postcss">
.menu-item {
  @apply cursor-pointer select-none grid grid-flow-col items-center auto-cols-max text-black-100;

  &__notifications {
    @apply px-2 py-0.5 rounded-xl bg-accent text-white-100 text-body-bold-12;
  }

  &--vertical {
    @apply px-4 py-2 gap-2 border-l-2 border-black-5 text-button w-full;

    &.menu-item--active {
      @apply text-accent border-accent;
    }
  }

  &--horizontal {
    @apply px-2 py-3 gap-2 border-b-2 border-transparent text-other;

    &.menu-item--active {
      @apply text-accent border-accent;
    }
  }
}
</style>
